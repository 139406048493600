import { createReducer } from "@reduxjs/toolkit"

import {
	CONFIG_ADD_GCLID,
	CONFIG_ADD_MSMLID,
	CONFIG_ADD_QUERY_STRING_VALUES,
	CONFIG_ADD_SESSION_ID,
	CONFIG_ADD_UTMCHATBOT,
	CLICK_COUNTER_CHATMUTUO,
} from "../actions/config.actions"

export const configReducer = createReducer(
	{
		settings: {
			clickChat: 0,
			utmchatbot: null,
			msmlid: null,
			gclid: null,
			sessionFeId: null,
			queryStringValues: {},
		},
	},
	builder =>
		builder
			.addCase(CLICK_COUNTER_CHATMUTUO, (state, action) => {
				state.settings.clickChat += action.payload
			})
			.addCase(CONFIG_ADD_UTMCHATBOT, (state, action) => {
				state.settings.utmchatbot = action.payload
			})
			.addCase(CONFIG_ADD_MSMLID, (state, action) => {
				state.settings.msmlid = action.payload
			})
			.addCase(CONFIG_ADD_GCLID, (state, action) => {
				state.settings.gclid = action.payload
			})
			.addCase(CONFIG_ADD_SESSION_ID, (state, action) => {
				state.settings.sessionFeId = action.payload
			})
			.addCase(CONFIG_ADD_QUERY_STRING_VALUES, (state, action) => {
				state.settings.queryStringValues = action.payload
			})
			
)
