import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	CONFIG_ADD_UTMCHATBOT,
	CLICK_COUNTER_CHATMUTUO,
} from "../state/actions/config.actions"

import { getUtmChatbot } from "../state/selectors/config.selector"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = ({
	children,
	to,
	target,
	className,
	activeClassName,
	partiallyActive,
	iubenda,
	clearUtmChat = false,
	...other
}) => {
	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to)
	// Use Gatsby Link for internal links, and <a> for others

	// console.log("this is iubenda")
	// console.log(iubenda)

	// if clearUtmChat, then clears utmChatbot from general state
	const dispatch = useDispatch()
	const utmChatBotGenerale = useSelector(getUtmChatbot)

	function handleUtm (){
		dispatch(CLICK_COUNTER_CHATMUTUO(1))
		if(utmChatBotGenerale){
			dispatch(CONFIG_ADD_UTMCHATBOT(null))
			
		}
		console.log("DENTRO HANDLE UTM")
	}
	
	if (internal) {
		if(clearUtmChat)
			{
				return (
					<GatsbyLink
						to={to}
						activeClassName={activeClassName}
						partiallyActive={partiallyActive}
						className={className}
						target={target}
						onClick={handleUtm}
						// data-iub-enabled={iubenda}
						{...other}
					>
						{children}
					</GatsbyLink>
				)
			}
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				className={className}
				target={target}
				// data-iub-enabled={iubenda}
				{...other}
			>
				{children}
			</GatsbyLink>
		)
	}

	return (
		<a href={to} target={target} 
			className={className} 
			
			// data-iub-enabled={iubenda} 
			{...other}>
			{children}
		</a>
	)
}
export default UniversalLink
